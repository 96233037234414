<template>
  <nav nav-mobile :data-open="mobile">
    <ul class="nav-mobile">
      <li v-for="(link, l) in config.header_menu" :key="l">
        <template v-if="link.tabs.length > 0">
          <div class="nav-link nav-parent" @click.prevent="toggleOpenLink(l, $event)" :class="{ 'nav--open': active === l }">
            <a :href="getLinkFieldUrl(link.link)" :target="link.link?.target">{{ link.label }}</a>
          </div>
          <div class="nav-items">
            <template v-for="(tab, s) in link.tabs" :key="s">
              <template v-for="(group, g) in tab.items" :key="g">
                <a class="nav-submenu-label" :href="getLinkFieldUrl(group.link)">{{ group.label }}</a>
                <ul class="nav-submenu">
                  <li v-for="(menu, m) in group.menu" :key="m">
                    <a :href="getLinkFieldUrl(menu.link)" :target="menu.link?.target" class="nav-link">{{ menu.label }}</a>
                  </li>
                </ul>
              </template>
            </template>
          </div>
        </template>
        <template v-else>
          <a :href="getLinkFieldUrl(link.link)" class="nav-link">{{ link.label }}</a>
        </template>
      </li>
    </ul>
    <div v-if="config.cta" class="nav-mobile-cta">
      <StoryblokComponent v-for="blok in config.cta" :key="blok._uid" :blok="blok" />
    </div>
  </nav>
</template>

<script setup>
import ButtonLink from "/components/snippet/Button";
const active = ref(null);
const toggleOpenLink = (index, event) => {
  if (event.target.tagName === "A") {
    window.location.assign(event.target.getAttribute("href"));
    return;
  }

  if (active.value === index) active.value = null;
  else active.value = index;
};

const props = defineProps({
  mobile: {
    type: Boolean,
    default: false,
  },
  config: {
    type: Object,
    required: true,
  },
});

useHead({
  bodyAttrs: {
    class: props.mobile ? "body--mobile-open" : "",
  },
});
</script>
